import axios from 'axios';

// Replace with your API secret key
//const API_ENDPOINT = 'http://localhost:1337/';
const API_ENDPOINT = process.env.REACT_APP_API_URL;
// Create an Axios instance with default configuration
const axiosInstance = axios.create({
  baseURL: API_ENDPOINT, // Replace with your API base URL
});

// Request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    // You can also perform logging or other actions before the request is sent
    return config;
  },
  (error) => {
    // Handle request errors here
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Modify the response data here (e.g., transform or process data)
    // You can also perform logging or other actions after the response is received
    return response;
  },
  (error) => {
    // Handle response errors here (e.g., show error messages)
    return Promise.reject(error);
  }
);

export default axiosInstance;
