import { collection, getDocs, addDoc, query, where, updateDoc, doc,serverTimestamp } from 'firebase/firestore';
import { db } from "../config/firebase-config";
import { useGetUserInfo } from "./useGetUserInfo";

export const useAddTransaction = () => {
    const transactionCollectionRef = collection(db, 'transactions');
    const { uid } = useGetUserInfo();

    const addTransaction = async ({ formData,type }) => {
        // Check if document exists with the same uid and formData.type
        const q = query(transactionCollectionRef, 
                        where('uid', '==', uid), 
                        where('type', '==', type));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            // If document exists, update it
            console.log('Document exists');
            console.log(formData);
            querySnapshot.forEach(async (doc) => {
                await updateDoc(doc.ref, {
                    formData,
                    updatedAt: serverTimestamp()
                });
            });
        } else {
            console.log('Document does not exist')
            // If document doesn't exist, create a new one
            await addDoc(transactionCollectionRef, {
                uid,
                type,
                formData,
                createdAt: serverTimestamp()
            });
        }
    };

    return {
        addTransaction
    };
};
