import React, { Component } from "react";
import { Button, Alert, Card } from "react-bootstrap";
import { useAddTransaction } from "../../hooks/useAddTransaction";
import PropTypes from "prop-types";
import "../../index.css"; // Import CSS file
class HomeForm extends Component {
  state = {
    fields: {},
    status: ""
  };

  initialFields = {};

  componentDidMount() {
    // Set initial state on component mount
    this.setState({ fields: { ...this.initialFields } });
  }


  render() {
    return (
      <div className="person-form-container">
        <div  className="data-entry"> 
          <h4>Data Entry Guide</h4>
        </div>  
          <Alert variant="info">
        <span style={{fontWeight:"bold"}}>Please follow the steps below to ensure accurate data entry:</span>
          <ol>
            <li>Go to each of the data schemas in the left sidebar</li>
            <li>Each of these schemas have multiple data groupings or tables that may correspond to a table or tables in your respective systems / organizations
              <br/>
              <p>Example:</p>
              <img src="data-grouping.png" alt="Data Groupings Example" style={{width: "30%", marginTop: "10px"}}/>
            </li>
            <li>For each data table, there several fields - these are listed under Field Name.  These maybe columns of tables or spreadsheets in your systems / organizations.  For each of the fields, follow the instructions in items # 4-7
              <br/>
              <p>Example:</p>
              <img src="fields.png" alt="Data Groupings Example" style={{width: "10%", marginTop: "10px"}}/>
            </li>
            <li>Indicate if it is Available in your organization, meaning the school is already storing this data for the respective schema.</li>
            <li>If the answer is yes to #4, for the respective field choose the Source: is it a spreadsheet, a software system, or other sources (physical/paper record).</li>
            <li>If the answer is no to #4, you may put a comment on the remarks textbox on why is this not being stored/track/recorded or if it its not relevant data.</li>
            <li>Put a comment on the Remarks about the source for example, if its a system please type in the system name.  If it is a spreadsheet, the master spreadsheet name.  If others, please type the source here.</li>
            <li>Click submit once you've completed filling up all the data.</li>
            <li>Once done with a table, proceed to the next and do items #4-8 again for each fields.</li>
            <li>Go to the next data schema in the sidebar and repeat the above steps until completed.</li>
          </ol>
        </Alert>
      </div>
    );
  }
}

HomeForm.propTypes = {
  // Define any prop types if necessary
};

export default HomeForm;
