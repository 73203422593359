import {addDoc, collection,serverTimestamp} from 'firebase/firestore';
import { db } from "../config/firebase-config";
import { useGetUserInfo } from "./useGetUserInfo"
import axiosInstance from './axiosMiddleware';
export const useAddUserInfo = () => {
    //
    let userInfoCollectionRef  = collection(db, 'user_info');
    const {uid} = useGetUserInfo();
    const addUserInfo = async ({formData}) => {
        await addDoc(
            userInfoCollectionRef,
            {
                uid,
                formData,
                createAt:serverTimestamp()

            });
    };
    return {
        addUserInfo
    };
}