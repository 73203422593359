import React, { Component } from "react";
import { Button, Alert, Card } from "react-bootstrap";
import { useAddTransaction } from "../../hooks/useAddTransaction";
import PropTypes from "prop-types";
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from "../../config/firebase-config";
import "../../index.css"; // Import CSS file
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import initialFieldsData from "./json/AssessmentDeliveryForm.json"; // Import the JSON file
import FormSection from "./FormSection"; // Import FormSection component
import Modal from "./Modal"; // MODAL
import { fetchDataFromAPI, submitFormData } from './formUtils'; // NODE
let formType = "Assessment Delivery Schema";
let description = "Represents the framework for delivering assessments within the educational system. It includes details on the administration of assessments, participant sessions, personal needs profiles, registration processes, assessment sessions, and the roles of staff involved in these sessions.";
class AssessmentDeliveryForm extends Component {
  state = {
    fields: {},
    status: "",
    showModal: false // MODAL
  };

  fetchData = async () => {//NODE
    try {
      const { uid } = JSON.parse(localStorage.getItem("user"));
      const data = await fetchDataFromAPI(uid, formType);
      if (data) {
        this.setState({ fields: data });
      } else {
        this.setState({ fields: this.initialFields });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  initialFields = initialFieldsData;

  componentDidMount() {
    // Set initial state on component mount

    this.setState({ fields: { ...this.initialFields } });
    this.fetchData();
  }

  handleChange = (fieldName, subFieldName, e) => {
    const { fields } = this.state;
    const newFields = {
      ...fields,
      [fieldName]: {
        ...fields[fieldName],
        [subFieldName]: e.target.value
      }
    };
    this.setState({ fields: newFields });
  };
  handleSubmit = async (e) => {//NODE
    e.preventDefault();

    const formData = {};
    for (const field in this.state.fields) {
      formData[field] = {
        'table': this.state.fields[field].table,
        'available': this.state.fields[field].available,
        'remarks': this.state.fields[field].remarks,
        'source': this.state.fields[field].source
      };
    }

    const { uid } = JSON.parse(localStorage.getItem("user"));

    try {
      await submitFormData(uid, formType, formData);
      this.setState({ status: "success", showModal: true });
    } catch (error) {
      console.error('Error submitting form:', error);
      this.setState({ status: "error" });
    }
  };
  handleCloseModal = () => {//MODAL
    this.setState({ showModal: false });
  };
  resetForm = () => {
    this.setState({ fields: { ...this.initialFields } });
  };

  render() {

    const { fields } = this.state;
    const tables = {};
    // Group fields by table
    for (const fieldName in fields) {
      const tableName = fields[fieldName].table;
      if (!tables[tableName]) {
        tables[tableName] = [];
      }
      tables[tableName].push({ fieldName, ...fields[fieldName] });
    }
    let errorAlert = (
      <Alert variant="danger">
        <Alert.Heading>Problem during submission</Alert.Heading>
        Please try again or contact service support for further help.
      </Alert>
    );

    let successAlert = (
      <Alert variant="success">
        <Alert.Heading>Survey Submitted</Alert.Heading>
        <p>Thank you for submitting the survey.</p>
      </Alert>
    );

    let alert = "";
    let showModal = this.state.showModal; //MODAL
    if (this.state.status === "error") {
      alert = errorAlert;
    } else if (this.state.status === "success") {
      alert = successAlert;
    }

    return (
      <>
      <FormSection
        formType={formType}
        description={description}
        alert={alert}
        tables={tables}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
      />
      {showModal && (
        <Modal handleClose={this.handleCloseModal}>
          <p>Form submitted successfully!</p>
        </Modal>
      )}
      </>
    );
  }
}


AssessmentDeliveryForm.propTypes = {
  // Define any prop types if necessary
};

export default AssessmentDeliveryForm;
