// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAtZ7cJV4TKn9Ly7ymPPIpoXOhKDG2vhNU",
  authDomain: "encoder-7c19e.firebaseapp.com",
  projectId: "encoder-7c19e",
  storageBucket: "encoder-7c19e.appspot.com",
  messagingSenderId: "768170514344",
  appId: "1:768170514344:web:83bd4b46a9676b1d85af9d",
  measurementId: "G-V463FRF5QK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const db = getFirestore();
export default app; 
//const analytics = getAnalytics(app);

//firebase login
//firebase init
//firebase deploy


/*


rules_version = '2';

service cloud.firestore {
  match /databases/{database}/documents {
    match /{document=**} {
      allow read, write: if false;
    }
  }
}

*/