import React from "react";
import { Card, Alert, Button } from "react-bootstrap";
import { Table, thead, tbody, tr, Th, td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import "../../index.css"; // Import CSS file
const FormSection = ({ formType, description, alert, tables, handleChange, handleSubmit }) => {
  return (
    <div className="person-form-container">
      <form onSubmit={handleSubmit}>
        <div  className="sticky-alert"> 
          <h4>{formType}</h4>
          <Alert variant="info">
            {description}
          </Alert>
        </div>

     
        {Object.keys(tables)
          .sort((a, b) => a.localeCompare(b))
          .map(tableName => (
            <Card key={tableName} style={{ marginBottom: "10px" }}>
              <Card.Header as="h5">{tableName}</Card.Header>
              <Card.Body style={{ overflowX: "auto", paddingTop: "0px", paddingBottom: "0px", paddingLeft: "10px", paddingRight: "10px" }}>
                <br />
                <table>
                  <thead>
                    <tr>
                      <th scope="col">Field Name</th>
                      <th scope="col">Available</th>
                      <th scope="col">Source</th>
                      <th scope="col">Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tables[tableName]
                      .sort((a, b) => a.fieldName.localeCompare(b.fieldName))
                      .map(field => (
                        <tr key={field.fieldName}>
                          <td  data-label="Field Name"><input type="hidden" name="table" value={field.table} />{field.fieldName.split("(")[0]}</td>
                          <td  data-label="Available">
                            <select
                              value={field.available}
                              onChange={e => handleChange(field.fieldName, "available", e)}
                            >
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </td>
                          <td  data-label="Source">
                            <select
                              value={field.source}
                              onChange={e => handleChange(field.fieldName, "source", e)}
                            >
                              <option value="Spreadsheet">Spreadsheet</option>
                              <option value="System">System</option>
                              <option value="Others">Others</option>
                            </select>
                          </td>
                          <td data-label="Remarks">
                            <input
                              type="text"
                              value={field.remarks}
                              onChange={e => handleChange(field.fieldName, "remarks", e)}
                              style={{ width: "100%" }}
                            />
                          </td>
                        </tr>
                      ))}
               
                    <tr>
                      <td colSpan="4" style={{ textAlign: "right" }}>
                        <Button color="primary" type="submit" >Save</Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
              </Card.Body>
            </Card>
          ))}
      </form>
    </div>
  );
};

export default FormSection;
