import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container, Button, Form } from "react-bootstrap";

import { login } from "./LoginActions.js";
import "../../index.css"; // Import CSS file
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
    };
  }
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onLoginClick = () => {
    const userData = {
      identifier: this.state.email,
      password: this.state.password
    };
    this.props.login(userData, "/dashboard");
  };
  render() {
    return (
      <Container className="container">
        <div className="form-container">
          <h3 className="title">Sign In to EDM - PHINMA</h3>
          <Form>
            <Form.Group controlId="emailId">
              <Form.Label className="label">Your Email</Form.Label>
              <Form.Control
                className="input"
                type="text"
                name="email"
                placeholder="Enter email"
                value={this.state.email}
                onChange={this.onChange}
              />
            </Form.Group>

            <Form.Group controlId="passwordId">
              <Form.Label className="label">Your password</Form.Label>
              <Form.Control
                className="input"
                type="password"
                name="password"
                placeholder="Enter password"
                value={this.password}
                onChange={this.onChange}
              />
            </Form.Group>

            <Button   color="primary"  variant="primary" block onClick={this.onLoginClick}>
              Login
            </Button>
          </Form>
          <p className="text-center mt-3">
            Don't have an account? <Link to="/signup">Signup</Link>
          </p>
        </div>
      </Container>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  login
})(withRouter(Login));