import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { auth } from "../../config/firebase-config";
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useAddUserInfo } from "../../hooks/useAddUserInfo"
import "../../index.css"; // Import CSS file
//import axios from "axios";
import { setAxiosAuthToken } from "../../utils/Utils";
import axiosInstance from '../../hooks/axiosMiddleware';
import {
  Alert,
  Container,
  Button,
  Row,
  Col,
  Form,
  FormControl
} from "react-bootstrap";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      email: "",
      organization:"",
      organizationError: "",
      position:"",
      positionError: "",
      usernameError: "",
      passwordError: "",
      emailError: "",
      status: "",
      errorMsg: ""
    };
  }
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSignupClick = async () => {
    this.setState({
      emailError: "",
      passwordError: "",
      status: ""
    });
  
    const formData = {
      username: this.state.email,
      email: this.state.email,
      password: this.state.password,
      organization: this.state.organization,
      position: this.state.position
    };
  
    try {
      const response = await axiosInstance.post('api/auth/local/register', formData);
      // If the request is successful, update the status to "success"
      //console.log('User profile', response.data.user);
      //console.log('User token', response.data.jwt);
      this.setState({ status: "success" });
    } catch (error) {
      // If an error occurs, update the status to "error" and handle the error
      this.setState({ status: "error" });
      /*if(response.data.message)
        this.setState({ errorMsg: response.data.message });
      else
        this.setState({ errorMsg: "An error occurred. Please try again." });  */
      this.setState({ errorMsg: error.response.data.error.message });
      console.log('An error occurred:', error.response.data.error.message);
    }
  };

  render() {
    const { errorMsg } = this.state;
    let errorAlert = (
      <Alert variant="danger">
        <Alert.Heading>Problem during account creation</Alert.Heading>
        {errorMsg}
      </Alert>
    );

    let successAlert = (
      <Alert variant="success">
        <Alert.Heading>Account created</Alert.Heading>
        <p>
          You may now login with your new account.
        </p>
      </Alert>
    );

    const form = (
      <div>
        <Form>
          <Form.Group controlId="emailId">
            <Form.Label className="label">Your Email</Form.Label>
            <Form.Control
              isInvalid={this.state.emailError}
              type="text"
              name="email"
              placeholder="Enter email"
              value={this.state.email}
              onChange={this.onChange}
            />
            <FormControl.Feedback type="invalid">
              {this.state.emailError}
            </FormControl.Feedback>
          </Form.Group>

          <Form.Group controlId="passwordId">
            <Form.Label className="label">Your password</Form.Label>
            <Form.Control
              isInvalid={this.state.passwordError}
              type="password"
              name="password"
              placeholder="Enter password"
              value={this.password}
              onChange={this.onChange}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.passwordError}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
        <Form.Group controlId="organization">
            <Form.Label className="label">Your Organization/Company</Form.Label>
            <Form.Control
              isInvalid={this.state.organizationError}
              type="text"
              name="organization"
              placeholder="Enter organization"
              value={this.state.organization}
              onChange={this.onChange}
            />
            <FormControl.Feedback type="invalid">
              {this.state.orgnizationError}
            </FormControl.Feedback>
        </Form.Group>
        <Form.Group controlId="position">
            <Form.Label className="label">Position</Form.Label>
            <Form.Control
              isInvalid={this.state.positionError}
              type="text"
              name="position"
              placeholder="Enter position"
              value={this.state.position}
              onChange={this.onChange}
            />
            <FormControl.Feedback type="invalid">
              {this.state.positionError}
            </FormControl.Feedback>
        </Form.Group>
        <Button color="primary" block onClick={this.onSignupClick}>
          Sign up
        </Button>
      </div>
    );

    let alert = "";
    if (this.state.status === "error") {
      alert = errorAlert;
    } else if (this.state.status === "success") {
      alert = successAlert;
    }

    return (
      <Container className="container">
        <div className="form-container">
            <h3 className="title">Sign Up to EDM - PHINMA</h3>
            {alert}
            {this.state.status !== "success" && form}
            <p className="text-center mt-3">
              Already have account? <Link to="/login">Login</Link>
            </p>
        </div>
      </Container>
    );
  }
}

Signup.propTypes = {};

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(withRouter(Signup));
