import React from 'react';
import "../../index.css"; // Import CSS file
import { Button, Alert, Card } from "react-bootstrap";
const Modal = ({ handleClose, children }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={handleClose}>&times;</span>
        <br />
        <Alert variant="success">
            <p>Thank you for submitting the survey.</p>
         </Alert>
      </div>
    </div>
  );
};

export default Modal;
