import axios from "axios";
import { push } from "connected-react-router";
import { toast } from "react-toastify";
import { SET_TOKEN, SET_CURRENT_USER, UNSET_CURRENT_USER } from "./LoginTypes";
import { setAxiosAuthToken, toastOnError } from "../../utils/Utils";
import { auth } from "../../config/firebase-config";
import { signInWithEmailAndPassword } from 'firebase/auth';
import axiosInstance from '../../hooks/axiosMiddleware';
// Revised login function with async/await
export const login = (userData, redirectTo) => async dispatch => {
  try {
    // Make the Axios POST request and await its response
    const response = await axiosInstance.post("api/auth/local", userData);
    
    // Extract the authentication token and user data from the response
    const { jwt } = response.data;
    dispatch(setToken(jwt));

    const user = {
      username: response.data.user.username,
      email: response.data.user.email,
      uid: response.data.user.id
    };
    
    // Set the current user and handle redirection
    dispatch(setCurrentUser(user, redirectTo));
  } catch (error) {
    // Handle specific error for invalid credentials
    dispatch(unsetCurrentUser());

    if (error.response && error.response.data && error.response.data.error) {
      const errorMessage = error.response.data.error.message;
      
      if (errorMessage === "Invalid identifier or password") {
        toastOnError("Invalid Username or Password");
      } else {
        toastOnError(errorMessage);
      }
    } else {
      // Generic error handling if the response is not in the expected format
      toastOnError(error.message || "An error occurred");
    }
    
    console.error('Error logging in:', error);
  }
};




export const getCurrentUser = redirectTo => dispatch => {
  axios
    .get("/api/v1/users/me/")
    .then(response => {
      const user = {
        username: response.data.username,
        email: response.data.email
      };
      dispatch(setCurrentUser(user, redirectTo));
    })
    .catch(error => {
      dispatch(unsetCurrentUser());
      if (error.response) {
        if (
          error.response.status === 401 &&
          error.response.hasOwnProperty("data") &&
          error.response.data.hasOwnProperty("detail") &&
          error.response.data["detail"] === "User inactive or deleted."
        ) {
          dispatch(push("/resend_activation"));
        }
      } else {
        toastOnError(error);
      }
    });
};

export const setCurrentUser = (user, redirectTo) => dispatch => {
  localStorage.setItem("user", JSON.stringify(user));
  dispatch({
    type: SET_CURRENT_USER,
    payload: user
  });

  if (redirectTo !== "") {
    dispatch(push(redirectTo));
  }
};

export const setToken = token => dispatch => {
  setAxiosAuthToken(token);
  localStorage.setItem("token", token);
  dispatch({
    type: SET_TOKEN,
    payload: token
  });
};

export const unsetCurrentUser = () => dispatch => {
  setAxiosAuthToken("");
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  dispatch({
    type: UNSET_CURRENT_USER
  });
};

export const logout = () => dispatch => {
  dispatch(unsetCurrentUser());
  dispatch(push("/"));
  toast.success("Logout successful.");
  /*axios
    .post("/api/v1/token/logout/")
    .then(response => {
      dispatch(unsetCurrentUser());
      dispatch(push("/"));
      toast.success("Logout successful.");
    })
    .catch(error => {
      dispatch(unsetCurrentUser());
      toastOnError(error);
    });*/
};
