import axiosInstance from '../../hooks/axiosMiddleware';

/**
 * Fetch data from the API based on uid and type
 * @param {string} uid - The user ID.
 * @param {string} type - The form type.
 * @returns {Object|null} - The form data or null if not found.
 */
export async function fetchDataFromAPI(uid, type) {
  try {
    const response = await axiosInstance.get('/api/transactions', {
      params: {
        'filters[uid][$eq]': uid,
        'filters[type][$eq]': type
      }
    });

    console.log('API response:', response.data);

    if (response.data && response.data.data && response.data.data.length > 0) {
      return response.data.data[0].attributes.form_data;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching data from API:', error);
    throw error;
  }
}

/**
 * Submit form data to the API
 * @param {string} uid - The user ID.
 * @param {string} type - The form type.
 * @param {Object} formData - The form data to submit.
 */
export async function submitFormData(uid, type, formData) {
  try {
    const response = await axiosInstance.get('/api/transactions', {
      params: {
        'filters[uid][$eq]': uid,
        'filters[type][$eq]': type
      }
    });

    console.log('API response:', response.data);

    if (response.data && response.data.data && response.data.data.length > 0) {
      const transactionId = response.data.data[0].id;
      await axiosInstance.put(`/api/transactions/${transactionId}`, {
        data: { form_data: formData }
      });
      console.log('Transaction updated:', transactionId);
    } else {
      await axiosInstance.post('/api/transactions', {
        data: { uid, type, form_data: formData }
      });
      console.log('Transaction created');
    }
  } catch (error) {
    console.error('Error submitting form:', error);
    throw error;
  }
}
